class HeartBeat {

    private interval;
    public onBeat = new LiteEvent();



    constructor() {
        this.Beat();
    }


    public Start = () => {
        if (!this.interval)
            this.interval = setInterval(() => {
                this.Beat().then((success) => {
                    this.onBeat.trigger();
                });
        }, 480000);
    }

    public Stop = function () {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null;
        }
    }

    private Beat() {
        var endpoint = "Account/RenewLicenseLease";
        return WinJS.xhr({
            type: "post",
            url: endpoint,
            //headers: { "Content-Type": "application/json" },
            //customRequestInitializer: function (xhr: XMLHttpRequest) {
            //    xhr.withCredentials = true;
            //}
        });
    }

}